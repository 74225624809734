
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        



























.searchtool__header {
  color: $c-white;
  background-color: $c-green-dark;
}

.searchtool__header__sentence,
.searchtool__header__keywords {
  width: col(14);
  margin: 0 col(1);

  @include mq(xl) {
    width: col(11);
  }
}

.searchtool .cta-inner {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}

.searchtool__cta {
  margin-top: $spacing * 4;
}
