
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




































































































































































.results-grid {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & + & {
    margin-top: $spacing * 3;
  }
}

.results-grid__title {
  @extend %text-capitalize;

  width: 100%;
  color: $c-gray-dark;
}

.results-grid-inner {
  margin-top: $spacing;
  width: 100%;

  @include mq(s) {
    display: flex;
    flex-flow: row wrap;
  }
}

.results-grid__highlights {
  flex-shrink: 0;
  width: 100%;
  min-width: auto;

  @include mq(m) {
    width: calc(50% - #{$spacing / 2});
  }
}

.results-grid__smalls {
  flex-shrink: 0;
  width: 100%;

  @include mq(s) {
    display: flex;
    flex-wrap: wrap;
  }

  @include mq(m) {
    width: calc(50% - #{$spacing / 2});
    margin-left: $spacing;
  }
}

.results-grid__smalls__item {
  min-width: auto;

  @include mq(s) {
    width: calc(50% - #{$spacing});
    height: auto;
    padding-bottom: $spacing * 2;

    &:nth-child(even) {
      margin-left: $spacing * 2;
    }
  }

  @include mq(m) {
    width: calc(50% - #{$spacing / 2});

    &:nth-child(even) {
      margin-left: $spacing;
    }
  }
}

.results-grid__mores {
  flex-shrink: 0;
  width: 100%;

  @include mq(s) {
    display: flex;
    flex-wrap: wrap;
  }
}

.results-grid__mores__item {
  width: 100%;
  min-width: auto;

  @include mq(s, m) {
    width: calc(50% - #{$spacing});

    &:nth-child(even) {
      margin-left: $spacing * 2;
    }
  }

  @include mq(m) {
    width: calc(25% - #{$spacing * 3 / 4});

    &:nth-child(4n + 2),
    &:nth-child(4n + 3),
    &:nth-child(4n + 4) {
      margin-left: $spacing;
    }
  }
}

.results-grid__btn {
  @extend %fw-bold;
  @extend %ff-alt;

  align-self: flex-end;
  margin-top: $spacing;
  padding: $spacing * 0.75 $spacing * 2;
  color: $c-white;
  background-color: $c-green-dark;
  cursor: pointer;

  &:hover {
    background-color: $c-green-light;
  }
}
