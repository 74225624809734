
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        








.storybook {
  display: flex;
  flex-direction: column;
  padding: 5rem;
}

.storybook__item {
  display: flex;
  // flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 1rem;
  padding: 1rem;
  border: 2px dotted $c-dev-error;

  &.fullscreen {
    > * {
      width: 100%;
    }
  }

  .card,
  [class*='card--'] {
    & + .card {
      margin-left: 2rem;
    }
  }
}
