
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        






































.academy-trainers__slider {
  margin-top: 0;
}
