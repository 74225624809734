
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        







































































.keywords {
  margin-top: $spacing;

  @include mq(l) {
    margin-top: $spacing * 2.5;
  }
}

.keywords__form {
  position: relative;
}

.keywords__form__input {
  width: 100%;
  min-height: 8rem;
  padding: $spacing / 2 $spacing;
  color: $c-white;
  font-size: 2.6rem;
  line-height: 1.2em;
  background-color: darken($c-green-dark, 4%);
  border: 1px solid darken($c-green-dark, 4%);
  outline: none;

  &::placeholder {
    color: rgba($c-white, 0.4);
  }
}

.keywords__form__btn {
  position: absolute;
  top: 50%;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  padding: 0;
  transform: translateY(-50%);
  background-color: $c-transparent;
  border: 0;
  outline: none;
  cursor: pointer;
}

.keywords__form__btn__icon {
  width: 18px;
  height: 18px;
  fill: $c-white;
  transition: fill 0.2s ease-out;

  .keywords__form__btn:hover & {
    fill: $c-green-light;
  }
}

.keywords__autocomplete {
  @extend %list-nostyle;

  display: flex;
  align-items: center;
  padding: $spacing / 2;
  background-color: darken($c-green-dark, 3%);
  border-top: 0;
}

.keywords__autocomplete__item {
  margin-right: $spacing / 4;
}

.keywords-bottom {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: $spacing;

  @include mq(m) {
    flex-direction: row;
  }
}

.keywords__tags__list {
  @extend %list-nostyle;

  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @include mq($until: m) {
    margin-top: $spacing;
  }

  @include mq(m) {
    margin-right: $spacing / 2;
  }
}

.keywords__tags__item {
  margin-right: $spacing / 2;
  margin-bottom: $spacing / 2;
}

.keywords__clear {
  @extend %fw-bold;

  position: relative;
  flex-shrink: 0;
  color: $c-white;
  font-size: 1.4rem;
  line-height: 1.2em;
  transition: color 0.2s ease-out;
  cursor: pointer;

  @include mq($until: m) {
    align-self: flex-end;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: $c-white;
    transition: background-color 0.2s ease-out;
  }

  &:hover {
    color: $c-yellow-medium;

    &::after {
      background-color: $c-yellow-medium;
    }
  }
}
