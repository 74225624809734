
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        












.filter-checkbox {
  @extend %ff-alt;

  display: flex;
  align-items: center;
  cursor: pointer;
  color: $c-green-dark;

  &:hover {
    color: $c-green-light;
  }
}

.filter-checkbox__box {
  position: relative;
  width: 14px;
  height: 14px;
  border: 2px solid $c-gray-light;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    background-color: $c-green-dark;
    transform: translate(-50%, -50%) scale(0);
    transform-origin: center center;
    transition: transform 0.2s ease-out;

    .filter-checkbox.is-checked & {
      transform: translate(-50%, -50%) scale(1);
    }
  }
}

.filter-checkbox__label {
  position: relative;
  top: 1px;
  flex-grow: 1;
  margin-left: $spacing / 2;
  font-size: 1.4rem;
  line-height: 1em;
}
