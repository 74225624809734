
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        






















































.about-collapse {
  border-bottom: 1px dashed $c-black;
}

.about-collapse__header {
  @extend %fw-bold;
  @extend %ff-alt;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $spacing 0;
  color: $c-green-dark;
  user-select: none;
  font-size: 1.8rem;
  line-height: 1.4em;
  transition: color 0.2s ease-out;
  cursor: pointer;

  .about-collapse.is-open &,
  .about-collapse:hover & {
    color: $c-green-light;
  }
}

.about-collapse__header__icon {
  flex-shrink: 0;
  overflow: visible;
  width: 20px;
  height: 20px;
  margin-left: $spacing;
  fill: $c-green-dark;
  transition: fill 0.2s ease-out;

  .about-collapse.is-open &,
  .about-collapse:hover & {
    fill: $c-green-light;
  }
}

[class*='about-collapse-inner--'],
.about-collapse-inner {
  overflow: hidden;
  margin-top: $spacing;

  &[class*='--grid'] {
    display: grid;
    grid-template-rows: max-content;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: $spacing;
  }
}

.about-collapse__group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &:last-child {
    margin-bottom: $spacing;
  }

  [class*='about-collapse-inner--'][class*='--grid'] & {
    grid-column: 1 / 2;
  }

  [class*='about-collapse-inner--'][class*='--grid'] &:nth-child(2) {
    margin-top: $spacing * 1.5;
  }

  [class*='about-collapse-inner--'][class*='--grid'] &:last-child {
    grid-row: 1 / 3;
    grid-column: 2 / 3;
  }
}

.about-collapse__group__title {
  @extend %text-uppercase;

  margin-bottom: $spacing / 2;
  color: $c-green-light;
  font-size: 1.2rem;
  line-height: 1.2em;
}

.about-collapse__group__item {
  position: relative;
  overflow: hidden;
  padding: $spacing / 4 0;
  color: $c-green-dark;
  font-size: 1.4rem;
  line-height: 1.2em;
  cursor: pointer;
  user-select: none;

  & + & {
    margin-top: $spacing / 4;
  }

  &:hover {
    text-decoration: underline;
  }

  &.is-active {
    color: $c-red-medium;
    text-decoration: underline;
    cursor: default;
    pointer-events: none;
    user-select: none;
  }
}
