
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        











































.results {
  margin-top: $spacing * 2;
}

.results-inner {
  width: col(14);
  margin: 0 col(1);
}

.results__none {
  @extend %fw-bold;
  @extend %text-center;
  @extend %text-uppercase;
  @extend %fw-bold;

  width: col(14);
  margin: 0 col(1);
  padding: $spacing;
  color: $c-gray-medium;
  font-size: 2.4rem;
  line-height: 1.2em;
  border: 1px dashed $c-gray-light;
}
