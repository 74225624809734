
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
































.filters-group {
  @extend %ff-alt;

  position: relative;
  width: 100%;
  font-size: 1.4rem;
  line-height: 1.2em;

  @include mq($until: m) {
    & + & {
      margin-top: $spacing;
    }
  }

  @include mq(m) {
    width: 33.33%;

    & + & {
      margin-left: -1px;
    }
  }
}

.filters-group__toggle {
  @extend %fw-bold;
  @extend %text-capitalize;

  position: relative;
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0 $spacing;
  font-size: 1.6rem;
  line-height: 1.2em;
  border: 1px solid $c-gray-light;
  cursor: pointer;

  &:hover {
    color: $c-green-light;
  }

  [class*='filters-group'].is-open &,
  .filters-group.is-open & {
    border-bottom-color: transparent;
  }
}

.filters-group__toggle__label {
  padding: 2rem 0;
}

.filters-group__toggle__arrow {
  @include center-y;

  right: 20px;
  width: 16px;
  height: 16px;
  fill: $c-black;
  transition: transform 0.2s ease-out;
  will-change: transform;

  .filters-group__toggle:hover & {
    fill: $c-green-light;
  }

  [class*='filters-group'].is-open &,
  .filters-group.is-open & {
    transform: translateY(-50%) rotate(180deg);
  }
}

.filters-group-inner {
  @include scrollbars;

  display: flex;
  align-items: flex-start;
  overflow-y: scroll;
  width: 100%;
  max-height: 300px;
  padding: $spacing / 2 $spacing $spacing;
  background-color: $c-white;
  border: 1px solid $c-gray-light;
  border-top: 0;

  @include mq(m) {
    position: absolute;
    z-index: 2;
    top: 49px;
    left: 0;
  }
}

.filters-group__list {
  @extend %list-nostyle;

  flex-grow: 1;
}

.filters-group__item {
  & + & {
    margin-top: $spacing * 0.75;
  }
}

.filters-group__item__label {
  cursor: pointer;
}

.filters-group__clear {
  @extend %fw-bold;

  position: relative;
  color: $c-green-dark;
  transition: color 0.2s ease-out;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: $c-green-dark;
    transition: background-color 0.2s ease-out;
  }

  &:hover {
    color: $c-green-light;

    &::after {
      background-color: $c-green-light;
    }
  }
}
