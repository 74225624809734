
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
















































































































.about-map {
  position: relative;
}

.about-map__svg {
  display: block;
}

.about-map__svg circle {
  fill: $c-gray-light;

  &.is-activable {
    fill: $c-green-light;
  }

  &.is-active {
    fill: $c-red-medium;
  }
}

.about-map__infos {
  @extend %ff-alt;

  width: 100%;
  padding: $spacing * 2;
  color: $c-green-dark;
  background-color: $c-white;
  border-radius: 10px;
  box-shadow: 0 0 5px 5px $c-gray-light;

  @include mq($until: s) {
    padding: $spacing;
  }

  @include mq($until: l) {
    max-width: 360px;
    margin-left: auto;
  }

  @include mq(l) {
    position: absolute;
    top: 0;
    left: 0;
    width: 340px;
    transform: rotate(0.001deg);
    transition: top 0.3s ease-out, left 0.3s ease-out;
  }
}

.about-map__infos__name {
  @extend %fw-bold;

  padding-bottom: $spacing * 1.5;
  font-size: 2.4rem;
  line-height: 1.2em;
  border-bottom: 1px dashed $c-green-dark;
}

.about-map__infos__location {
  @extend %fw-bold;

  margin-top: $spacing * 1.5;
  font-size: 1.6rem;
  line-height: 1.2em;
}

[class*='about-map__infos__data--'],
.about-map__infos__data {
  display: flex;
  align-items: flex-start;
  margin-top: $spacing;
  font-size: 1.4rem;
  line-height: 1.4em;
}

.about-map__infos__data__icon-outer {
  position: relative;
  top: -2px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 24px;
  height: 24px;
}

.about-map__infos__data__icon {
  fill: $c-green-light;
}

.about-map__infos__data-inner {
  @extend %text-nodecoration;

  margin-left: $spacing / 2;
}
