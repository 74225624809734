
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        




























































































































.about-offices {
  @include default-margin;

  background-color: $c-gray-lighter;
}

.about-offices-inner {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;

  @include mq(l) {
    flex-direction: row;
  }
}

.about-offices__collapses {
  flex-shrink: 0;
  width: col(14);
  margin: 0 col(1);

  @include mq(l) {
    width: 390px;
  }
}

.about-offices__map {
  width: col(14);
  margin: 0 col(1);

  @include mq(l) {
    flex-grow: 1;
  }
}
