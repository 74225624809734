
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        































.home__onboard {
  @include get-all-space;

  position: fixed;
  z-index: 9999;
  display: block;
  background-color: $c-white;
}

.home__onboard__logo {
  @include center-xy;

  width: 30vw;
  height: 10rem;
}
