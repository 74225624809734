
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        








































































.media-center__nav {
  margin-top: 1.8rem;
}
.media-center__nav-inner {
  @include mq($until: s) {
    width: col(14);
    margin-left: col(1);
  }

  @include mq(s) {
    width: col(14);
    margin-left: col(1);
  }
}

.media-center__nav__link {
  @extend %fw-bold;

  margin-right: $spacing * 1.5;
  padding-bottom: $spacing * 0.25;
  color: $c-gray-medium;
  font-family: $ff-alt;
  text-decoration: none;
  border-bottom: 3px solid transparent;

  &:hover {
    color: $c-green-dark;
  }

  &.router-link-exact-active {
    pointer-events: none;
    color: $c-green-dark;
    border-bottom: 3px solid $c-green-light;
  }
}
