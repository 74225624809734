
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        


































.case-studies {
  .content-search__grid {
    margin-top: $spacing * 2;
  }
}
