
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        











.searchtool-sentence {
  @extend %ff-alt;
}

.searchtool-sentence__headline {
  @extend %fw-bold;

  color: $c-white;
  font-size: 1.8rem;
  line-height: 1.2em;
  opacity: 0.4;
}

.searchtool-sentence__text {
  margin-top: $spacing * 0.75;
  line-height: 1.2em;

  strong {
    color: $c-yellow-medium;
  }
}
