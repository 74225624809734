
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        






























.card-grid {
  width: 100%;
  margin-bottom: 2rem;
  padding-right: col(1);
  padding-left: col(1);
}

.card-grid__title {
  color: $c-gray-medium;
  font-weight: 400;
}

.card-grid__layout {
  margin-top: $spacing * 2;

  @include mq(l) {
    display: flex;
    flex-wrap: wrap;
  }
}

.card-grid__item--highlight {
  width: 100%;
  margin: 0;

  @include mq(l) {
    width: col(6, 14);
  }
}

.card-grid__items {
  width: 100%;
  padding: 0;

  @include mq(l) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: top;
    width: col(7, 14);
    margin-left: col(1, 14) !important; /* stylelint-disable-line */
  }
}

.card-grid__item {
  width: 100%;

  @include mq(l) {
    width: col(3, 7);
    // min-width: 27rem;
  }
}

.card-grid__btn {
  display: flex;
  justify-content: flex-end;
}

// Mobile

// .page-nav-inner {
//   position: relative;
//   transition: color 0.1s ease-out, border-color 0.1s ease-out;
//   cursor: pointer;
//   user-select: none;

//   .dropdown.is-selected & {
//     color: $c-green-dark;
//   }

//   .is-open & {
//     height: auto;
//     border: 1px solid $c-gray-light;

//     .ie & {
//       overflow: visible;
//     }
//   }
// }

// .page-nav__label {
//   overflow: hidden;
//   padding-right: $spacing * 3;
//   white-space: nowrap;

//   .page-nav.is-open & {
//     color: $c-green-light;
//   }
// }

// .page-nav__items {
//   @include scrollbars;

//   position: absolute;
//   z-index: 9;
//   top: 100%;
//   left: 0;
//   display: flex;
//   flex-direction: column;
//   align-items: flex-start;
//   overflow-y: scroll;
//   width: 100%;
//   max-height: 300px;
//   background-color: $c-white;
//   border: 1px solid $c-gray-light;
//   border-top: 0;
// }

// .page-nav__item {
//   position: relative;
//   display: flex;
//   align-items: center;
//   flex-shrink: 0;
//   width: 100%;
//   height: 50px;
//   padding: 0 $spacing * 2.5 0 $spacing;
//   white-space: nowrap;
//   cursor: pointer;

//   a {
//     text-decoration: none;
//   }

//   &:hover {
//     background-color: $c-green-dark;

//     a {
//       color: $c-white;
//     }
//   }

//   &.is-selected {
//     padding-left: $spacing * 1.5;
//     color: $c-green-dark;
//     background-color: $c-green-light;
//     pointer-events: none;

//     &::before {
//       content: '';
//       position: absolute;
//       top: 50%;
//       left: 20px;
//       width: 2px;
//       height: 1em;
//       background-color: $c-green-dark;
//       transform: translateY(-50%);
//     }
//   }
// }

// .page-nav__item__arrow {
//   position: absolute;
//   z-index: 2;
//   top: 50%;
//   right: $spacing;
//   display: block;
//   width: 24px;
//   height: 24px;
//   fill: $c-green-dark;
//   user-select: none;
//   pointer-events: none;
//   transform: translateY(-50%) rotate(0);
//   transition: fill 0.2s ease-out, transform 0.2s ease-out;
//   will-change: transform;

//   .page-nav.is-open & {
//     transform: translateY(-50%) rotate(180deg);
//   }

//   .page-nav.is-selected & {
//     transform: translateY(-50%) translateX(-30px);
//   }

//   .page-nav.is-open.is-selected & {
//     transform: translateY(-50%) translateX(-30px) rotate(180deg);
//   }
// }
